import { useIntl } from 'react-intl';
import Head from 'next/head';

export function AppHead(props: {}) {
  const intl = useIntl();
  const siteTitle = intl.formatMessage({
    defaultMessage: 'Discord自动聊天机器人 - Discord智能聊天助手',
  });
  const siteDesc = intl.formatMessage({
    defaultMessage:
      '使用基于先进AI大模型驱动的Discord自动聊天机器人，轻松活跃您的群组气氛。提供智能对话、实时响应和个性化互动，提升社区参与度和用户体验。适用于各种Discord群组，助您打造充满活力的在线社区。',
  });
  return (
    <Head>
      <title>{siteTitle}</title>
      <link rel="icon" href="/favicon.ico" />
      <meta name="description" content={siteDesc} />
      <meta property="og:title" content={siteTitle} />
      <meta property="og:description" content={siteDesc} />
      <meta property="og:url" content="https://discordsuperman.xyz/" />
      <meta
        property="og:site_name"
        content={intl.formatMessage({
          defaultMessage: 'Discord 智能聊天助手',
        })}
      />
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:description" content={siteDesc} />
      <meta name="twitter:title" content={siteTitle} />
      <meta name="twitter:image" content="/assets/discord-superman-logo.png" />
      <meta name="twitter:site" content="@Discord智能聊天助手" />
      <meta name="twitter:creator" content="@DiscordSuperMan" />
    </Head>
  );
}
